import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Cta from '@components/content/Cta';
import Image from '@components/utility/Image';
import Hero from '@components/content/hero/Hero';
import Loadable from '@loadable/component';

//styles
import './styles.scss';

const WhizzbangLine = Loadable(() => import('@components/content/WhizzbangLine'));


const WeightAndBehaviour = () => {
	return (
		<Layout>
			<Seo title='Tolerability: Weight & Behavior | EMFLAZA® (deflazacort)' description='Learn more about the potential impact of EMFLAZA® (deflazacort). View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--weight-behaviour xl:pr-48">

				<Hero heroImage='hero-3-2.png' heroImageMobile='hero-3-2-mobile.png' breadcrumbs={['Tolerability', 'Weight & Behavior']} title={<span>Weight and behavior with deflazacort</span>} addedClass='py-2' />

				<div className="max-w-4xl px-8 text-emflaza-blue-600 md:px-0">
					<div className='flex flex-wrap mt-16 md:gap-10 md:mt-52'>
						<p className='flex-1'>
							<span className="font-bold">Deflazacort has a smaller suppressive effect</span> on the <br className='hidden md:inline' /> hypothalamic-pituitary adrenal (HPA) axis vs prednisone; considering a small concentration of deflazacort crosses the blood brain barrier reaching the HPA axis, <span className="font-bold">a reduced risk of behavioral disorders</span> has been hypothesized.<sup>6</sup>
						</p>
						<div className='flex-1 min-w-[250px] text-center mt-10 md:mt-0'>
							<Image imageName='icon-brain-3-2.png' alt="Illustration of a human brain in cross-section beside a set of kidneys, representing the HPA axis"></Image>
						</div>
					</div>

					{/* Mid section with blue bg START */}
					<div className="relative">
						<div className="absolute section-bg -top-24 md:-top-40 -left-8 xl:-left-40 2xl:left-[-40%] 3xl:-left-2/4 3xl:-top-32 z-negative">
							<Image imageName='middle-section-bg-3-2.png' imageNameMobile='middle-section-bg-3-2-mobile.png' alt=""></Image>
						</div>
						<div className="mt-32 mb-24 md:mb-11 2xl:mb-32 3xl:mt-44 3xl:mb-44">
							<div>
								<h2 className="my-10 text-xl font-bold text-center uppercase font-gotham 2xl:my-16 md:text-2xl text-emflaza-blue-400">Compared to those on prednisone, patients taking deflazacort in clinical trials were<sup>7</sup></h2>
							</div>

							<div className="flex flex-col items-center space-y-10 text-center md:space-y-0 md:gap-10 md:flex-row">
								<div className="flex-1">
									<Image imageName='middle-section-icon-1.png' alt=""></Image>
									<p className="my-5">Less likely to <span className="font-bold highlightedText">gain weight</span></p>
								</div>
								<div className="flex-1">
									<Image imageName='middle-section-icon-2.png' alt=""></Image>
									<p className="my-5">Less likely to experience <span className="font-bold highlightedText">psychiatric side effects</span> (ie, abnormal behavior, aggression, irritability, and <br className='hidden md:inline' /> mood swings)</p>
								</div>
								<div className="flex-1">
									<Image imageName='middle-section-icon-3.png' alt=""></Image>
									<p className="my-5">Less likely to <span className="font-bold highlightedText">discontinue treatment</span> due to side effects</p>
								</div>
							</div>
						</div>
					</div>
					{/* Mid section with blue bg END */}

					<div className="bottom-section">
						<div className='hidden md:block'>
							<WhizzbangLine imageName='whizzbang-line-2.png' addedClass="top-12" />
						</div>
						<ul className='bulleted-list'>
							<li className="flex mb-6">
								<span>
									Psychiatric adverse events generally appeared at a higher rate in the prednisone group vs both deflazacort groups<sup>7</sup>
								</span>
							</li>
							<ul className="circled-list md:ml-20 3xl:ml-0">
								<li>Abnormal behavior, aggression, irritability, and mood swings were more commonly reported in the prednisone group</li>
								<li>Psychotic disorder was reported more frequently in the deflazacort group</li>
							</ul>
						</ul>
					</div>

					<div>
						<h2 className="font-bold">Study Limitations</h2>
						<p>Comparisons between deflazacort and prednisone are not included in the Prescribing Information for deflazacort because prednisone is not an approved treatment for DMD.</p>
					</div>

					<div className="flex flex-wrap justify-center mb-8 space-y-5 xl:justify-start md:space-y-0 md:space-x-5 mt-14">
						<Cta ctaUrl='/efficacy/real-world-data' iconUrl={'cta-world-icon-2.png'} ctaText='Real-world Data' addedClass='cta flex-1' iconAddedClass='max-w-[63px]' />
						<Cta ctaUrl='/dosing-administration' iconUrl={'cta-dosing-icon.png'} ctaText='Dosing & Administration' addedClass='cta flex-1' iconAddedClass='max-w-[52px]' />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default WeightAndBehaviour;
